#header {
  //background: #293443;
  background: rgba(0,0,0,0.85) !important;
  height: 50px;
  left: 0;
  overflow: hidden;
  padding: 0;
  right: 0;
  top: 0;
  z-index: 1;
  color: #fff !important;
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.08);
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.08);
  //mix-blend-mode: color-dodge;

  .ant-menu-horizontal {
    line-height: 55px;
    border-bottom: 0;
    line-height: 50px;
    font-size: 0.9em;

    #manage {
      padding: 0px;
      
      .ant-btn {
        background-color: transparent;
        border: 0;
        font-size: 0.9em;
        height: 44px;
        padding: 0 20px;
      }
    }
    button {
      font-size: 1em !important;
    }
  }

  .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 0;
    top: 0;
  }

  .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 0;
  }
}


@keyframes header-slide-up {
  from {
    top: 0;
  }

  to {
    top: -64px;
  }
}


@keyframes header-slide-down {
  from {
    top: -64px;
  }

  to {
    top: 0;
  }
}
