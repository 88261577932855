#content {
  .page-header {
    background-color: rgba(255, 255, 255, .5);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);
    //height: 80px;
    padding: 15px;
    position: relative;
    z-index: 1000;
    -webkit-box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);

    .page-icon {
      border-radius: 25px;
      color: #fff;
      float: left;
      font-size: 25px;
      height: 50px;
      line-height: 50px;
      margin-right: 15px;
      text-align: center;
      width: 50px;
    }

    .page-title {
      color: rgba(0, 0, 0, .5);
      line-height: 1em;
      margin-bottom: 0;
      padding: 0;
    }

    .page-description {
      color: rgba(0, 0, 0, .5);
      margin: 0;
    }

    .center {
      text-align: center;
    }
  }
}
