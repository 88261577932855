.edit-faena {
    .edit {
        .piezas {
            .info {
                padding: 1px;
                background-color: rgba(0,0,0,.1);
                width: 100px;
                margin: 5px;
                text-align: center;
                border-radius: 4px;
                margin-bottom: 10px;
            }
        }
    }
}