.setup {
    color: rgba(0,0,0,.8);
    
    .tools-area {
        border-radius: 2px;
        padding: 5px;
        text-align: right;

        button {
            margin-left: 15px;
        }
    }

    .edit-faena {
        background-color: rgba(0,0,0,.03) !important;
        border-radius: 3px;
        border: 1px solid rgba(0,0,0,.06);
        .tools-area {
            background-color: rgba(255,255,255,.85) !important;

            .title {
                font-weight: bold;
                text-align: left;
                padding-left: 5px;;
            }
            .createAt {
                padding-right: 10px;
            }
        }
    }

    .new-faena {
        width: 99%;
        border: 3px rgba(0,0,0,.08) solid;

        .tools-area {
            background-color: rgba(0,0,0,.05) !important;
        }

        .body-new-faena {
            padding: 5px;
        }
    }
}
.modal-config {
    .content-config {
        height: 100%;
        background-color: rgba(0,0,0,.07);
        padding: 10px;
        color: rgba(0,0,0,.8);
        display:flex;
        align-items:center;

        .ant-col {
            display: flex;
            align-items:center;
            padding: 10px;
        }
    }
}