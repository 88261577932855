body {
    padding: 0px !important;
}

@media (max-width: 800px) {
    .login-page {
        padding-top: 80px !important;
    }
}

@media (max-width: 600px) {
    .login-page {
        padding-top: 40px !important;
    }
}

.login-page {
    padding-top: 120px;
    height: 100vh;
    //background-color: rgba(0,0,0,.4);
    background-color:rgba(0,0,0,.9);
    a {
            color: #fff;
    }

/* III.- TERCER BLOQUE - INICIO DE SESIÓN */      
    .tercerBloque {
        block-size: 480px;
        position: relative;
    }
    .tercerBloque::after {
        content:"";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: .95;
    }
    .tercerBloque::after {
        z-index: 1;
    }
    .tercerBloque > * {
        z-index: 100;
    }
    .seccionSesion {
        padding: 100px 0px 30px 70px;
    }
    
    .cajaIngreso {
        align-self: center;
        margin: auto;
        width: 90%;
        max-width: 600px;
        border-radius: 5px;
        //background-color:rgba(0,0,0,.7);
        padding: 10px;

        .row-image {
            text-align: center;
            padding: 10px;
            img {
                width: 90%;
            }
        }
    }
    
    #logoIngreso {
        width: 100%;
    }
    #ingresoUsuario {
        inline-size: 510px;
        border: 0px none rgb(109, 109, 109);
        font: 16px / 27.2px Raleway, sans-serif;
        padding: 10px 20px;
        margin-bottom: 0px;
        display: inline-block;
        width: 100%;
    }
    .ant-form-item {
        margin-bottom: 10px;
    }
    #botonIngresar {
        text-align: center;
    
        button {
            block-size: 30px;
            background-color: rgb(250, 119, 60);
            color: white;
            font: 12px / 22.2px Raleway, sans-serif;
        }
    }
    .olvidoCon {
        //block-size: 13px;
        color: white;
        text-align: center;
        font: 12px Raleway, sans-serif;
        padding: 10px;
    }

}