.navigation {
  background: transparent;
  //float: right;

  .ant-menu-item, .ant-menu-submenu-title {
    height: 50px !important;
    color: #fff !important;
  }

  .ant-btn {
    background-color: transparent;
    border: 0;
    //font-size: 0.9em;
    //height: 44px;
  }

  .menu-projects {
    margin-left: 10px;
  }

.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 0;
  position: relative;
  top: 0;
  vertical-align: top;
}

  .anticon {
    margin-right: 6px !important;
  }
}
