.edit-faena {
    .edit {
        .avance {
            .stage {
                padding-top: 10px;
                border-radius: 3px;

                .indicators-ex {
                    padding-top: 2px;
                    .indicator {
                        //height: 30px;
                        width: 100%;
                        background-color: rgba(254, 244, 60, .3);
                        color: rgba(0,0,0,.7);
                        padding: 3px 10px;
                    }
                }

                .title-stage {
                    background-color: rgba(250, 199, 16, .5);
                    padding: 5px;
                    font-size: larger;

                    .data-title {
                        text-align: right;
                        font-size: .9em;
                        
                        span.info {
                            font-size: .97em;
                            background-color: white;
                            margin-left: 10px;
                            margin-right: 25px;
                            padding: 2px 4px;
                            border-radius: 2px;
                            border: 1px solid rgba(0,0,0,.1); 
                            width: 130px;
                            display: inline-block;
                            text-align: center;

                            &.duration, &.interruption {
                                width: 60px !important;
                            }

                            &.datetime {
                                width: 150px;
                                padding: unset;
                            }
                        }
                    }
                }
                .data-table {
                    margin-top: 5px;

                    .row-task-GIRO {
                        background-color: rgba(0,0,255,.03);
                    }
                }
            }
        }
    }
}

.modalInterruptions {
    td {
        vertical-align: unset;
    }
    pre {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        margin-bottom: 0px;
    }
}