#footer {
  width: 100%;
  bottom: 0px;
  background:rgba(0,0,0,0.85);
  box-shadow: inset 0px 45px 28px -33px rgba(0,0,0,0.1);
  -webkit-box-shadow: inset 0px 45px 28px -33px rgba(0,0,0,0.1);
  -moz-box-shadow: inset 0px 45px 28px -33px rgba(0,0,0,0.1);
  position: relative;
  height: 60px;


 .footer-inner {
   margin: 0 auto;
   padding: 10px 0 25px;
   //width: 1200px;
   color: rgba(255, 255, 255, .7);
   padding: 10px;
  }

 .footer-bottom {
   border-top: 1px rgba(255, 255, 255, .12) solid;
   color: rgba(255, 255, 255, .8);
   padding: 10px 0;
   text-align: center;
   width: 100%;
 }

 .powered {
   span {
     padding-right: 10px;

     a {
       color: rgba(255, 255, 255, .7);
       text-decoration: underline;
     }
   }

   img {
     height: 20px;
   }
 }

 .col-inner {
 }

 .selector {
   list-style-type: none;
   margin: 0;
   padding: 0;

   li {
     color: rgba(255, 255, 255, .7);
     cursor: pointer;
     border-radius: 5px;
     overflow: hidden;
     padding: 7px 10px;

     &.selected {
       background: rgba(0, 0, 0, .1);

       .anticon {
         display: block !important;
       }
     }

     .theme-color {
       background: #fff;
       border-radius: 10px;
       float: left;
       height: 20px;
       margin-right: 10px;
       width: 20px;
     }

     img {
       margin-right: 10px;
     }

     a {
       color: rgba(255, 255, 255, .7);
       line-height: 18px;
     }

     .anticon {
       display: none;
       float: right;
       position: relative;
       right: 5px;
       top: 4px;
     }
   }
 }
}

#inicio #footer .navigation li#button-footer-home,
#consulta #footer .navigation li#button-footer-query,
#masivos #footer .navigation li#button-footer-batch,
#registro #footer .navigation li#button-footer-register,
#administracion #footer .navigation li#button-footer-administration {
  background: rgba(0, 0, 0, .1);

  .anticon-check {
    display: block;
  }
}
