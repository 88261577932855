.logo {
  img {
    height: 50px;
    padding: 5px;
    vertical-align: inherit;
  }

  @media (max-width: 600px) {
    img {
      width: 100px;
      height: unset;
      margin-top: 7px;
    }
  }
}
