.step1 {
    .tools {
        padding: 15px 0px;

        .next-step {
            float: right;
            margin-right: 10px;

            .anticon {
                font-size: 20px;
                color: black;
            }
        }
    }
}