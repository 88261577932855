
#inicio {

  .home {
    background-color: rgba(0,0,0,.1);
    min-height: 583px;

    @media (max-width: 600px) {
      .title-mills {
        padding-left: 10px !important;
        padding-right: 10px !important;

        div>div {
          font-size: 1.3em !important;
          line-height: 40px !important;
        }

        img {
          display: none;
          height: 20px !important;
          top: 12px !important;
        }
      }

      div.module {
        .title {
          padding-left: 10px !important;
        }
      }
    }

    .title-mills {
      background-color: rgba(0, 0, 0, 0.85);
      max-height: 55px;
      padding: 0px 14px 0px 16px;
      margin-bottom: 10px;
      border-radius: 3px;

      color: rgba(255, 255, 255, 0.9);
      div>div {
        line-height: 44px;
        font-size: 1.4em;
      }
      
      img {
        height: 55px;
        position: absolute;
        top: -3px;
        right: 0px;
      }
    }

    .page-content {
      padding: 20px;

      div.module {
        margin-bottom: 10px;
  
        .title {
          padding: 6px 12px;
          background-color: rgba(0, 0, 0, 0.83);
          margin-bottom: 8px;
          border-radius: 3px;

          a {
            color: rgba(255, 255, 255, 0.9);
          }
          .anticon {
            font-size: 30px;
          }
          div {
            position: absolute;
            top: 0px;
            font-size: 1.4em;
            margin: -11px 42px;
          }
        }

        div.submodule {
          margin-bottom: 5px;
          .content {
            height: 100%;
            min-height: 100px;
            background-color: rgba(255,255,255,.9);

            box-shadow: 10px 10px 40px -25px rgba(0,0,0,0.5);
            -webkit-box-shadow: 10px 10px 40px -25px rgba(0,0,0,0.5);
            -moz-box-shadow: 10px 10px 40px -25px rgba(0,0,0,0.5);
          }

          ul {
            margin: 0px;
            padding-inline-start: 20px;
          }

          &.name {
            width: 100%;
            max-width: 160px;
            max-height: 160px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            font-size: medium;
            position: relative;

            &:before {
              background-color: rgba(0,0,0,.85);
              border-radius: 5px;
              content:     "";
              display:     block;
              padding-top: 100%; /* initial ratio of 1:1*/
              top: -50px;
              width: 100%;
            }

            span {
              color: rgba(255,255,255,.9);
              position: absolute;
              left: 0px;
              top: 40%;
              width: 95%;
            }
          }
        }

        .ant-card {
          background-color: transparent;

          .ant-card-body {
            padding: 12px;
          }

          .ant-card-head {
            min-height: unset;

            .ant-card-head-title {
              padding: 2px
            }
            }
        }
      }
    }
  }
    
}