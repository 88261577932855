.step3 {
    .tools {
        padding: 15px 0px;

        .next-step {
            float: right;
            margin-right: 10px;
        }
        .anticon {
            font-size: 20px;
            color: black;
        }
    }
    .btnAdd {
        margin-top: 5px;
    }
    .btn-edit {
        margin-left: 10px;
    }
}