.admin-users {
  .tools-area {
    border-radius: 2px;
    //margin-bottom: 20px;
    padding: 5px;
    text-align: right;

    #create-user {
    }
  }

  .disabled {
    color: #bbb;
  }

  div.ant-table-body {
    height: 420px;
  }

  .ant-pagination {
    text-align: center;
    float: none;
  }
}
