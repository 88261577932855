.current-user {
  line-height: 50px;

  .ant-btn {
    background-color: transparent;
    border: 0;
    //font-size: 0.9em;
    //height: 44px;
    padding: 12px 30px;
    float: right;
  }

  .ant-dropdown {
    font-size: 0.9em !important;
    width: 500px !important;
  }
}

.menu-current-user .ant-dropdown-menu {
  //background-color: rgba(255, 255, 255, .7) !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
