.edit-faena {
    .edit {
        .tools-btn {
            padding-top: 10px;
            text-align: right;

            button {
                margin-right: 10px;
            }
        }
        .section {
            border: 1px solid rgba(0,0,0,.2);
            border-radius: 3px;
            padding: 10px;
            margin-top: 10px;
            //padding-left: -10px;
            background-color: rgba(255,255,255,.75) !important;

            .title {
                padding: 2px 8px;
                font-size: 1.1em;
                font-weight: 500;
                background-color: rgba(4, 4, 43, 0.05);
                margin-bottom: 5px;

                .ver-mas {
                    font-size: .9em;
                    text-align: right;
                }
            }

            .fields {
                padding-top: 10px;

                .ant-form-item {
                    margin-bottom: 12px;
                }
            }

            .modo-act-online {
                color: green;
            }
            .modo-act-offline {
                color: red;
            }
        }
        .tools {
            padding: 15px 0px;

            .next-step {
                float: right;
                margin-right: 10px;

                .anticon {
                    font-size: 20px;
                    color: black;
                }
            }
        }
    }
}