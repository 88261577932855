.edit-faena {
    .edit {
        .avance {
            .title {
               .indicadores {
                   text-align: right;

                   label {
                       padding: 0px 10px;
                       margin-left: 10px;
                   }
                   span {
                       padding: 1px 5px;
                       background-color: white;
                       border: 1px solid rgba(0,0,0,.2);
                       border-radius: 2px;
                       min-width: 120px;
                       text-align: center;
                       display: inline-block;
                   }
               }
            }
            .indicators {
                padding-top: 10px;
                .indicator {
                    float: left;
                    width: 25%;
                    text-align: center;
                    height: 55px;
                    border-radius: 3px;

                    .content-indicator {
                        padding: 5px;
                        background-color: rgba(254, 244, 60, .3);
                        color: rgba(0,0,0,.7);
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}