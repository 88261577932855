.page-bottom-bar {
  padding-top: 5px;

  .go-to-top-link {
    bottom: 7px;
    color: rgba(0, 0, 0, .5);
    cursor: pointer;
    float: right;
    position: relative;
    top: 11px;
  }
}
