.dashboard {
    .title-mills {
        background-color: rgba(0, 0, 0, 0.85);
        max-height: 55px;
        padding: 0px 14px 0px 16px;
        margin-bottom: 8px;
        border-radius: 3px;
  
        color: rgba(255, 255, 255, 0.9);
        div>div {
          line-height: 44px;
          font-size: 1.4em;
        }
        
        img {
          height: 55px;
          position: absolute;
          top: -3px;
          right: 0px;
        }
    }

    @media (max-width: 600px) {
        .title-mills {
          padding-left: 10px !important;
          padding-right: 10px !important;
  
          div>div {
            font-size: 1.3em !important;
            line-height: 40px !important;
          }
  
          img {
            display: none;
            height: 20px !important;
            top: 12px !important;
          }
        }
    }

    .block {
        background-color: rgba(0,0,0,.1);
        margin-bottom: 10px;
        padding: 10px;

        .turno-header {
            background-color: rgba(0,0,0,.8);
            color: white;
            text-align: center;
        }
        .stat-stage {
            padding: 3px;
        }
        .stat-number {
            text-align: center;
        }

        .title {
            font-size: 1.1em;
            background-color: rgba(0,0,0,.8);
            color: rgba(255,255,255,.9);
            padding: 5px 5px;
            border-radius: 2px;
        }

        .indicators {
            padding-top: 10px;

            .indicator {
                //margin-right: 20px;
                height: 100%;

                padding: 10px;
                background-color: white;
                font-size: larger;
                line-height: 18px;

                .number {
                    font-size: 2em;
                    margin-top: 5px;
                    text-align: center;
                }
            }
        }

        .indicador-porcentaje {
            margin: auto;
            margin-top: 5px;
            text-align: center;
            height: 95px;
            width: 95px;
            padding-top: 25px;

            position: relative;

            .number {
                color: white;
                position: absolute;
                z-index: 10;
                text-align: center;
                width: 100%;
            }

            .octagon{
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                overflow: hidden;
            }
            .octagon:before {
                background-color: rgba(0,0,0,.8);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: rotate(45deg);
                content: '';
            }
        }

        .indicador-avance {
            height: 60px;

            .indicador-block {
                height: 100%;

                .indicador-number {
                    width: 50px;
                    height: 100%;
                    background-color: rgba(0,0,0,.8);
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    color: white;
                    padding: 10px;
                    text-align: center;
                    line-height: 30px;
                }
            }
        }
    }
    
}