.step5 {
    .tools {
        padding: 15px 0px;

        .anticon-left {
            font-size: 20px;
            color: black;
        }
        .save {
            float: right;
            margin-right: 10px;
        }
    }

    .ant-form-item {
        margin-bottom: 4px;

        .ant-form-item-label {
            text-align: left;
        }
    }
    .ant-collapse-header {
        padding: 6px 10px !important;
        padding-left: 35px !important;
    }
}