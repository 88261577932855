#app {
  .layout-private.ant-layout {
    background: transparent !important;
    min-height: 100%;

    .bar {
      margin: 0 auto;
      width: 1200px;
    }
  }
}

.ant-switch-checked {
  background: #2cbe10;
}

.anticon-question-circle {
  color: #777;
}

.camelized {
  text-transform: capitalize;
}

.risk-GRAY {
  background: #999 !important;
  color: #fff !important;
}

.risk-BLACK {
  background: rgba(0, 0, 0, 0.7) !important;
  color: #fff !important;
}

.risk-RED {
  background: #d9534f !important;
  color: #fff !important;
}

.risk-ORANGE {
  background: #FE9F0C !important;
  color: #fff !important;
}

.risk-YELLOW {
  background: #fcdc6b !important;
  color: rgba(0, 0, 0, .3);
}

.risk-GREEN {
  background: #598756 !important;
  color: #fff !important;
}

#header {
  .navigation li#home-button.ant-menu-item-selected {
    background-color: #1890ff;
    color: #fff;
  }
}
